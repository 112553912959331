import React from "react";
import { Subject } from "rxjs";
import { fromFetch } from "rxjs/fetch";
import { takeUntil, switchMap, map } from "rxjs/operators";
import { GetLibrary } from "../../Common/ApiUtils";
import { LibraryModel } from "../../Common/Library.Model";
import { LibraryProps } from "./LibraryProps";
import { GameModel } from "../../Common/Game.Model";

export class Library extends React.Component<LibraryProps> {  
   private unsubscribe = new Subject<void>();
   private result = 0;
   
   private games = new Array<GameModel>();

   componentDidMount(): void {
      fromFetch(GetLibrary(this.props.user?.steamIdentity || "unknown")).pipe(
         takeUntil(this.unsubscribe),
         switchMap((response) => response.json()),
         map((response) => response.response)
      ).subscribe((response: LibraryModel) =>{     
         this.result = response.game_count; 
         this.games = response.games;
         this.setState({
            result: this.result,
            games: response.games.sort((a: GameModel, b: GameModel) => {return b.playtime_forever - a.playtime_forever;})
         });
      }); 
   }

   componentWillUnmount(): void{      
      this.unsubscribe.next();      
   }
   

   render(): React.ReactNode {
      return (
         <div className="Library">
            <div>Total Games: {this.result}</div>
            <table>
               <tr>
                  <th>Name</th>
                  <th>Hours</th>
               </tr>
               {this.games.map((item) => 
                  // eslint-disable-next-line react/jsx-key
                  <tr>
                     <td>{item.name}</td>
                     <td>{Math.round(item.playtime_forever / 60)}</td>
                  </tr>
               )}
            </table>       
         </div>
      );
   }
}