import React from "react";
import { AuthenticationModel } from "./AuthenticationModel";

export const AuthenticationContext = React.createContext<AuthenticationModel>(
   new AuthenticationModel(
      () => { throw new Error("AuthModel not initialized"); },
      () => { throw new Error("AuthModel not initialized"); },
      () => { throw new Error("AuthModel not initialized"); },
      () => { throw new Error("AuthModel not initialized"); },
      () => { throw new Error("AuthModel not initialized"); },
      null
   )
);
