import React from "react";
import "./PlayListToDo.scss";

export class PlayListToDo extends React.Component {
   render(): React.ReactNode {
      return (
         <div className="PlayListToDo">
            <h2>PlayList - Rough Algorithm Plan</h2>
            <ol>
               <li>Pull Library</li>
               <ul>
                  <li>Probably should use &quot;include_played_free_games&quot;</li>
               </ul>
               <li>Allow user to choose and exclude certain games</li>
               <ol>
                  <li>Start storing user info in SimpleDB</li>
                  <li>Add quick exclude</li>
                  <li>Add a management screen to reinclude afterwards</li>
               </ol>
               <li>Allow user to choose specific Persona</li>
               <ul>
                  <li>Personas/Moods - what kind of game to you feel like playing today?</li>
                  <li>Customizable/Configurable</li>
               </ul>
               <li className="Rejected">Sort by &quot;Nearness to completion&quot;</li>
               <ul className="Rejected">
                  <li>Simple version - % achievement completion</li>
                  <li>Harder version - Nearness to Main Story Completion</li>
                  <ul>
                     <li>Only applies to games with a main story</li>
                     <li>Steam doesn&apos;t have &quot;Game Completed&quot; this concept in it&apos;s achievement model</li>
                     <li>Games with multiple endings would have multiple ending achievements</li>
                     <li>Representing &quot;Nearness&quot; to completion would also mean tracking required story achievements, not just the last one</li>
                  </ul>
                  <li>Other ideas</li>
                  <ul>
                     <li>User Defined Goals, like playtime or specific achievements</li>
                  </ul>
               </ul>
               <li>Sort by Peer Pressure</li>
               <ul>
                  <li className="Done">Calculate points based on achievements completed</li>
                  <li className="Done">Worth different amounts based on community completion rate</li>
                  <li className="Done">Worth negative points for unachieved items</li>
                  <li className="Done">Normalize scores out of 100 for each game, based on max and min, to make the games more comparable</li>
                  <li>Add normalized columns to view</li>
                  <li>Fix table behavior on mobile</li>
                  <li>Add a nicer achievement tooltip</li>
                  <li>Persist results from steam, probably per 24 hours or so, to reduce calls to steam API</li>
                  <li>Figure out what to do about achievement-less games</li>
                  <li>Add Friends List comparison</li>
               </ul>
            </ol>
         </div>
      );
   }
}