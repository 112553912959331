import React from "react";
import "./PlayList.scss";
import { LibraryProps } from "../Library/LibraryProps";
import { PlayListView } from "./PlayListView/PlayListView";
import { PlayListToDo } from "./PlayListToDo/PlayListToDo";
import { BrowserRouter, Link, Switch, Route } from "react-router-dom";

export class PlayList extends React.Component<LibraryProps> {
   render(): React.ReactNode {
      return (
         <div className="PlayList">
            <hr/>
            <BrowserRouter>
               <nav className="PlayListNav">
                  <ul>
                     <li>
                        <Link to="/playlist">To Do</Link>
                     </li>
                     <li>
                        <Link to="/playlist/view">View</Link>
                     </li>
                  </ul>
               </nav>
               <Switch>
                  <Route path="/playlist/view">
                     <PlayListView user={this.props.user}></PlayListView>
                  </Route>
                  <Route path="/playlist">
                     <PlayListToDo></PlayListToDo>
                  </Route>
               </Switch>
            </BrowserRouter>
         </div>
      );
   }
}