import React from "react";
import {
   BrowserRouter,
   Switch,
   Route,
} from "react-router-dom";
import "./UnauthenticatedApp.scss";
import { UnauthenticatedHome } from "./UnauthenticatedHome";
import { SteamLoginComplete } from "./SteamLoginComplete";

export const UnauthenticatedApp: React.FC = props => {
   return (
      <BrowserRouter>
         <Switch>
            <Route path="/SteamLoginComplete">
               <SteamLoginComplete {...props} />
            </Route>
            <Route path="/">
               <UnauthenticatedHome />
            </Route>
         </Switch>
      </BrowserRouter>
   );   
};