import React from "react";
import {useAuthentication} from "../../Context/Authentication/useAuthentication";

export const UnauthenticatedHome: React.FC = () => {
   return (
      <div className="UnauthenticatedHome">
         <div className="Logo large-screen">Gaming.WithAPlan</div>
         <div className="Logo medium-screen">Gaming.WithAPlan</div>
         <div className="Logo small-screen">Gaming WithAPlan</div>
         <div className="Subtitle">Beat the real final boss - your game backlog</div>
         <img className="Login" alt="Sign in through STEAM" src={window.location.origin  + "/images/SteamLogin.png"} onClick={useAuthentication().login} />
      </div>
   );   
};