import { GameModel } from "./Game.Model";
import { PlayListAchievementModel} from "./PlayListAchievement.Model";


export class PlayListGameModel{
   appID: number;
   name: string;
   playtimeForever: number;
   peerPressureScore: number;
   comment = "";
   nextAchievement: PlayListAchievementModel;
   achievements: Array<PlayListAchievementModel>;
   nextAchievementsToolTip: string;

   constructor(game: GameModel){
      this.appID = game.appid;
      this.name = game.name;
      this.playtimeForever = Math.round(game.playtime_forever / 60);
      this.achievements = new Array<PlayListAchievementModel>();
   }
}