import React from "react";
import "./ToDo.scss";

export class ToDo extends React.Component {
   render(): React.ReactNode {
      return (
         <div className="ToDo">
            <ol>
               <li className="Done">Make Project</li>
               <li className="Done">Make GitHub Repo</li>
               <li className="Done">Make AWS Bucket</li>
               <li className="Done">Set up CodePipeline</li>
               <li className="Done">Set up Domain</li>
               <li className="Done">Set up SSL</li>
               <li className="Done">Set up Steam Auth</li>
               <ol>
                  <li className="Done">Figure out React authentication</li>
                  <li className="Done">Create hello world flask api</li>
                  <li className="Done">setup api hosting in AWS</li>
                  <li className="Done">Make call to steam API</li>
               </ol>
               <li>AWS Hosting</li>
               <ol>
                  <li className="Done">setup domain/hosting in AWS</li>
                  <li className="Done">Figure out proxy/rewrite lambda</li>
                  <li>figure out environment variables</li>
               </ol>
               <li className="Done">Load Game Library</li>
               <li>Add client side session</li>
               <li>Add some server side persistence</li>
               <li>Build PlayList</li>
               <ol>
                  <li className="Done">Sketch out algorithm</li>
                  <li>Build Display</li>
                  <li>Build Personas</li>
                  <li>Incorporate Personal Preferences, like ignoring games</li>
               </ol>
               <li>Build Soul Mate Finder</li>
               <ol>
                  <li className="Done">Sketch out algorithm</li>
                  <li>Build algorithm</li>
                  <li>Build Display</li>
                  <li>Incorporate Personas</li>
                  <li>Incorporate Personal Preferences, like time zone settings</li>
               </ol>
            </ol>
         </div>
      );
   }
}