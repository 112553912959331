import { PlaylistAchievementTransferModel } from "./PlaylistAchievement.TransferModel";

export class PlayListAchievementModel{
   Name: string;
   Description: string;

   constructor(achievement: PlaylistAchievementTransferModel){      
      this.Name = achievement.name;
      this.Description = achievement.description;
   }
}