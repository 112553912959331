import React from "react";
import "./Soulmate.scss";
import { LibraryProps } from "../Library/LibraryProps";

export class Soulmate extends React.Component<LibraryProps> {
   render(): React.ReactNode {
      return (
         <div className="Soulmate">
            <h2>Soulmate - Rough algorithm plan</h2>
            <ol>
               <li>Pull Library</li>
               <ul>
                  <li>Probably should use &quot;include_played_free_games&quot;</li>
               </ul>
               <li>Allow user to choose and exclude certain games</li>
               <ol>
                  <li>Start storing user info in SimpleDB</li>
               </ol>
               <li>Sort by &quot;Importance to player&quot; (or persona)</li>
               <ul>
                  <li>Simple version - Time Played</li>
                  <li>Other ideas</li>
                  <ul>
                     <li>Achievement Percentage</li>
                     <li>Time played when compared to global average</li>
                     <li>Achievement Percentage  when compared to global average</li>
                  </ul>
               </ul>
               <li>
                  Find other users that have a matching (within stdev) &quot;Importance&quot;
               </li>
               <li>
                  Repeat the process for the next most &quot;Important&quot; game
               </li>
               <li>
                  Intersect the lists
               </li>
               <li>
                  Repeat process until there is 1 player remaining - that&apos;s your soulmate!
               </li>
               <li>
                  This almost definitely will not work, and is too simple. Enhancements:
                  <ul>
                     <li>Instead of boolean yes/no for &quot;Importance&quot; matching, make it a scale (probably based on stdDev, again)</li>
                     <li>Instead of intersecting lists based on a threshold, keep adding similarity scores until one other player becomes obviously an outlier</li>
                     <li>Include bought but unplayed games - similarity in being bad with money is still a similarity</li>
                     <li>Weight some games by certain factors - probably based on tags. I love sci-fi, so someone who only plays a few games, but they are all sci-fi, should be rated as more similar</li>
                     <li>Include time since last played - I used to play a ton of Dota 2, but not as much now</li>                     
                  </ul>
               </li>
            </ol>
         </div>
      );
   }
}