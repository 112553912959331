import { UserModel } from "./UserModel";

export class AuthenticationModel {
    login: () => void;
    logout: () => void;
    register: () => void;
    updateUser: (user: UserModel | null) => void;
    processSteamLogin: (steamIdentity: string) => void;
    user: UserModel | null;

    constructor(
       login: () => void, 
       logout: () => void, 
       register: () => void, 
       updateUser: (user: UserModel | null) => void, 
       processSteamLogin: (steamIdentity: string) => void,
       user: UserModel | null) {
       this.login = login;
       this.logout = logout;
       this.register = register;
       this.updateUser = updateUser;
       this.processSteamLogin = processSteamLogin;
       this.user = user;
    }
}
