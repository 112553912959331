export function GetApiUrl(steamID: string, endpoint: string): string {
   let apiPrefix: string;
   if(window.location.hostname === "localhost"){
      apiPrefix = "";
   } else if (window.location.hostname === "www.gaming.withaplan.com"){
      apiPrefix = "/api";
   } else{
      throw new Error(`Unsupported host name detected: ${window.location.hostname}`);
   }

   return `${apiPrefix}/steam/${endpoint}?steamIdentity=${steamID}`;
}

export function GetUsernameUrl(steamID: string): string {
   return GetApiUrl(steamID, "username");
}

export function GetLibrary(steamID: string): string {
   return GetApiUrl(steamID, "library");
}

export function GetPlaylistGame(steamID: string, appid: number): string {
   return GetApiUrl(steamID, "playlistgame") + `&gameID=${appid}`;
}