import React from "react";
import "./TopBar.scss";
import { useAuthentication } from "../../Context/Authentication/useAuthentication";

export const TopBar: React.FC = () => {
   return (
      <div className="TopBar">
         <div className="Logo">
                Gaming.WithAPlan
         </div>
         <div className="UserSection">
            <div className="UserName">{useAuthentication().user?.username}</div>
            <input type="button" className="Logout" onClick={useAuthentication().logout} value="logout"/>
         </div>
      </div>
   );
};