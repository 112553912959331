import React, { useEffect } from "react";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useAuthentication } from "../../Context/Authentication/useAuthentication";

export const SteamLoginComplete: React.FC = () => {  
   const location = useLocation(); 
   const authentication = useAuthentication();
   const history = useHistory();

   useEffect(() => {
      const values = queryString.parse(location.search);

      console.log(values["openid.identity"]);
      console.log(values["openid.assoc_handle"]);
      console.log(values["openid.sig"]);
      console.log(values["openid.sig"]);
      console.log(values["openid.ns"]);

      const identityRegex = "^https://steamcommunity.com/openid/id/([0-9]*?)$";
      const identityString = values["openid.identity"]?.toString() ?? "unknown";

      const matches = identityString.match(identityRegex);

      let steamIdentity = "unknown";
      if (matches && matches.length === 2){
         steamIdentity = matches[1];
      }

      authentication.processSteamLogin(steamIdentity);
      
      return (): void => {
         history.push("/");
      };
   }); 
   
   return (
      <div className="UnauthenticatedHome">
         <div>setting up session...</div>            
      </div>
   );    
};
