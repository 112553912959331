import React from "react";
import PropTypes from "prop-types";
import { AuthenticationProvider } from "../Context/Authentication/AuthenticationProvider";

export const AppProviders: React.FC = props => {
   return (
      <AuthenticationProvider>
         {props.children}
      </AuthenticationProvider >
   );
};
AppProviders.propTypes = {
   children: PropTypes.node.isRequired
};
