import React from "react";
import "./PlayListView.scss";
import { LibraryProps } from "../../Library/LibraryProps";
import { Subject } from "rxjs";
import { PlayListGameModel } from "../../../Common/PlayListGame.Model";
import { PlayListGameTransferModel } from "../../../Common/PlayListGame.TransferModel";
import { fromFetch } from "rxjs/fetch";
import { GetLibrary, GetPlaylistGame } from "../../../Common/ApiUtils";
import { takeUntil, switchMap, map } from "rxjs/operators";
import { LibraryModel } from "../../../Common/Library.Model";
import { PlayListAchievementModel } from "../../../Common/PlayListAchievement.Model";

export class PlayListView extends React.Component<LibraryProps> {
   private unsubscribe = new Subject<void>();
   private result = 0;
   
   private games = new Array<PlayListGameModel>();
   private gameMap = new Map<number, PlayListGameModel>();

   componentDidMount(): void {
      const steamIdentity = this.props.user?.steamIdentity;        
      fromFetch(GetLibrary(steamIdentity || "unknown")).pipe(
         takeUntil(this.unsubscribe),
         switchMap((response) => response.json()),
         map((response) => response.response)
      ).subscribe((response: LibraryModel) =>{     
         this.result = response.game_count;
         this.games = response.games.map(g => new PlayListGameModel(g));         
         this.gameMap = new Map(this.games.map(g => [g.appID, g]));

         this.setState({
            result: this.result,
            games: this.sortGames()
         });
      }); 
   }

   componentWillUnmount(): void{      
      this.unsubscribe.next();      
   }

   private sortGames(): Array<PlayListGameModel>{
      return this.games.sort((a: PlayListGameModel, b: PlayListGameModel) => {
         if(a.peerPressureScore === undefined || b.peerPressureScore === undefined){
            if(a.peerPressureScore !== undefined && b.peerPressureScore === undefined){
               return -1;
            }
            if(a.peerPressureScore === undefined && b.peerPressureScore !== undefined){
               return 1;
            }
            if(a.playtimeForever === b.playtimeForever){
               return a.name.localeCompare(b.name);
            }
            return b.playtimeForever - a.playtimeForever;
         } else {
            if(a.comment !== "" && b.comment === ""){
               return 1;
            }
            if(a.comment  === "" && b.comment !== ""){
               return -1;
            }
            if(a.peerPressureScore === b.peerPressureScore){
               return a.name.localeCompare(b.name);
            }
            return b.peerPressureScore - a.peerPressureScore;
         }
      });
   }
   
   private loadScore(appid: number): void {  
      const steamIdentity = this.props.user?.steamIdentity;    
      fromFetch(GetPlaylistGame(steamIdentity || "unknown", appid)).pipe(
         takeUntil(this.unsubscribe),
         switchMap((response) => response.json()),
      ).subscribe((response: PlayListGameTransferModel) =>{   
 
         const game = this.gameMap.get(appid);
         if(game){
            game.peerPressureScore = 0;
            if(response.normalizedPeerPressureTotal !== undefined){
               game.peerPressureScore = response.normalizedPeerPressureTotal;
               game.nextAchievement = new PlayListAchievementModel(response.achievementsToDo[0]);
               let achievementCount = response.achievementsToDo.length;
               game.achievements = new Array<PlayListAchievementModel>();
               game.nextAchievementsToolTip = "";
               if(achievementCount > 5){
                  achievementCount = 5;
               }
               for(let i = 0; i < achievementCount; i++){
                  game.achievements.push(new PlayListAchievementModel(response.achievementsToDo[i]));
                  game.nextAchievementsToolTip +=  response.achievementsToDo[i].name + " - " + response.achievementsToDo[i].description + "\n";
               }
            } else{
               game.comment = "No Steam Achievements";
            }
            this.setState({
               games: this.sortGames()
            });
         }         
      }); 
   }

   render(): React.ReactNode {
      return (
         <div className="PlayListView">
            <div>Total Games: {this.result}</div>
            <table className="PlayListViewTable">
               <colgroup>
                  <col className="NameColumn"/>
                  <col className="HoursColumn"/>
                  <col className="ScoreColumn"/>
                  <col className="NextAchievementColumn"/>
                  <col className="NoteColumn"/>
               </colgroup>
               <thead>
                  <tr>
                     <th>Name</th>
                     <th>Hours</th>
                     <th>Peer Pressure</th>
                     <th>Next</th>
                     <th>Note</th>
                  </tr>
               </thead>
               <tbody>
                  {this.games.map((item, i) => 
                     <tr key={i}>
                        <td title={item.name}>{item.name}</td>
                        <td>{item.playtimeForever}</td>
                        {item.peerPressureScore !== undefined ? <td>{Math.round(item.peerPressureScore)}</td> : <td className="LoadScoreButton" onClick={(): void => {this.loadScore(item.appID);}}>Load</td>}
                        <td title={item.nextAchievementsToolTip}>{item.nextAchievement?.Description}</td>
                        <td title={item.name}>{item.comment}</td>
                     </tr>
                  )}
               </tbody>
            </table>       
         </div>
      );
   }
}