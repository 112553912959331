import React from "react";
import { TopBar } from "../../Components/TopBar/TopBar";
import { ToDo } from "../../Components/ToDo/ToDo";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import "./AuthenticatedApp.scss";
import { Library } from "../../Components/Library/Library";
import { PlayList } from "../../Components/PlayList/PlayList";
import { Soulmate } from "../../Components/Soulmate/Soulmate";
import { useAuthentication } from "../../Context/Authentication/useAuthentication";

export const AuthenticatedApp: React.FC = () => {
   const auth = useAuthentication();
   return (
      <div className="AuthenticatedApp">
         <TopBar></TopBar>
         <div className="AppContent">
            <BrowserRouter>
               <nav>
                  <ul>
                     <li>
                        <Link to="/">To Do</Link>
                     </li>
                     <li>
                        <Link to="/library">Library</Link>
                     </li>
                     <li>
                        <Link to="/playlist">PlayList</Link>
                     </li>
                     <li>
                        <Link to="/soulmate">Soul Mate</Link>
                     </li>
                  </ul>
               </nav>
               <Switch>
                  <Route path="/library">
                     <Library user={auth.user}></Library>
                  </Route>
                  <Route path="/playlist">
                     <PlayList user={auth.user}></PlayList>
                  </Route>
                  <Route path="/soulmate">
                     <Soulmate user={auth.user}></Soulmate>
                  </Route>
                  <Route path="/">
                     <ToDo></ToDo>
                  </Route>
               </Switch>
            </BrowserRouter>
         </div>
      </div>
   );   
};