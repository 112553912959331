import React from "react";
import { AuthenticationContext } from "./AuthenticationContext";
import { AuthenticationModel } from "./AuthenticationModel";
import { UserModel } from "./UserModel";
import { AuthenticationProps} from "./AuthenticationProps";
import Cookies from "universal-cookie";
import { Subject } from "rxjs";
import { fromFetch } from "rxjs/fetch";
import { takeUntil, switchMap } from "rxjs/operators";
import { GetUsernameUrl } from "../../Common/ApiUtils";

export class AuthenticationProvider extends React.Component<AuthenticationProps> {   
   auth: AuthenticationModel;

   private user: UserModel | null = null;

   private static steamIDCookieName = "gwap-steamid";

   private unsubscribe = new Subject<void>();

   constructor(props: AuthenticationProps){
      super(props);
      this.auth = this.buildAuthenticationModel();
      const cookies = new Cookies();
      const steamID = cookies.get(AuthenticationProvider.steamIDCookieName);
      if(steamID){
         this.processSteamLogin(steamID);
      }
   }

   componentWillUnmount(): void{
      this.unsubscribe.next();
   }

   render(): React.ReactNode  {
      return (
         <AuthenticationContext.Provider value={this.auth} {...this.props} />
      );
   }

   private login(): void {
      const steamUrl = "https://steamcommunity.com/openid/login";
      let appUrl: string;

      if(window.location.hostname === "localhost"){
         appUrl = "http://localhost:3000";
      } else if (window.location.hostname === "www.gaming.withaplan.com"){
         appUrl = "https://www.gaming.withaplan.com";
      } else{
         throw new Error(`Unsupported host name detected: ${window.location.hostname}`);
      }
      const queryParameters = new Array<string>();

      queryParameters.push("openid.ns=http://specs.openid.net/auth/2.0");
      queryParameters.push("openid.identity=http://specs.openid.net/auth/2.0/identifier_select");
      queryParameters.push("openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select");
      queryParameters.push("openid.mode=checkid_setup");
      queryParameters.push(`openid.return_to=${appUrl}/SteamLoginComplete`);
      queryParameters.push(`openid.realm=${appUrl}`);

      const authUrl = `${steamUrl}?${queryParameters.join("&")}`;

      window.location.href = authUrl;
   }

   processSteamLogin(steamID: string): void{    

      fromFetch(GetUsernameUrl(steamID)).pipe(
         takeUntil(this.unsubscribe),
         switchMap((response) => response.text())
      ).subscribe((result) =>{     
         
         const cookies = new Cookies();
         cookies.set(AuthenticationProvider.steamIDCookieName, steamID, { path: "/" });    
         const user = new UserModel();
         user.steamIdentity = steamID;
         user.username = result;
         this.updateUser(user);
      });
   }

   private register(): void {
      alert("register");
   }

   private logout(): void {
      const cookies = new Cookies();
      cookies.remove(AuthenticationProvider.steamIDCookieName); 
      this.updateUser(null);
   }

   private updateUser(user: UserModel | null): void{
      this.user = user;
      this.auth = this.buildAuthenticationModel();
      this.setState({auth: this.auth});
   }

   private buildAuthenticationModel(): AuthenticationModel{
      return new AuthenticationModel(
         this.login.bind(this), 
         this.logout.bind(this), 
         this.register.bind(this), 
         this.updateUser.bind(this),
         this.processSteamLogin.bind(this),
         this.user);
   }
}
