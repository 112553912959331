import React from "react";
import "./App.css";
import { AuthenticatedApp } from "../Entry/AuthenticatedApp/AuthenticatedApp";
import { UnauthenticatedApp } from "../Entry/UnauthenticatedApp/UnauthenticatedApp";
import { useAuthentication } from "../Context/Authentication/useAuthentication";

const App: React.FC = () => {
   return (
      <div className="App">
         {useAuthentication().user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </div>
   );
};

export default App;
